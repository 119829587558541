import Linkedin from '@/assets/svgs/linkedin.png';
import instagram from '@/assets/svgs/instagram.png';
import discord from '@/assets/svgs/discord.png';

export const socialLinks = [
  {
    href: 'https://www.linkedin.com/company/hackingly/',
    icon: Linkedin,
    alt: 'LinkedIn',
  },
  {
    href: 'https://www.instagram.com/hackingly_/',
    icon: instagram,
    alt: 'Instagram',
  },
  {
    href: 'https://discord.gg/QeVEdpe4',
    icon: discord,
    alt: 'Discord',
  },
];

export const navigationLinks = {
  company: [
    { href: '/about', label: 'About Us' },
    { href: '/events', label: 'Events' },
    { href: '/community', label: 'Community', isNew: true },
    { href: '/careers', label: 'Jobs & Internship' },
  ],
  resources: [
    { href: '/blog', label: 'Blogs', isNew: true },
    { href: '/faqs', label: 'FAQs' },
    { href: '/support', label: 'Support' },
  ],
  legal: [
    { href: '/terms', label: 'Terms of Service' },
    { href: '/privacy-policy', label: 'Privacy Policy' },
  ],
};
