'use client';
import React, { useState, useEffect, useLayoutEffect } from 'react';
import {
  Navbar,
  NavbarBrand,
  NavbarContent,
  Link,
  Dropdown,
  DropdownTrigger,
  DropdownItem,
  DropdownMenu,
} from '@nextui-org/react';
import Image from 'next/image';
import lightLogo from '../../assets/images/companyLogo.png';
import SignInForm from '../forms/auth/SignInForm';
import { usePathname, useRouter } from 'next/navigation';
import RegisterForm from '../forms/auth/RegisterForm';
import CTAButton from '../commons/CTAButton';
import { signOut, useSession } from 'next-auth/react';
import { baseURL } from '@/utils/BaseURL';
import axios from 'axios';
import toast from 'react-hot-toast';
import Avatar from '@/components/Avatar';
import { useSearchParamsWrapper } from '@/app/auth/page';

export default function HomeNavbar() {
  const [isLoginModalOpen, setLoginModalOpen] = useState(false);
  const [isSignupModalOpen, setSignupModalOpen] = useState(false);
  const router = useRouter();
  const pathname = usePathname();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userData, setUserData] = useState(null);
  const [profileDropdownItems, setProfileDropdownItems] = useState([]);
  const [redirectPath, setRedirectPath] = useState('');

  const { data: session, status } = useSession();
  const { getParam } = useSearchParamsWrapper();
  const utmSearchParams = getParam('utm');

  useLayoutEffect(() => {
    if (status === 'authenticated') {
      if (session?.error === 'RefreshAccessTokenError') {
        signOut();
        toast.error('Session Expired, Please Login Again');
        return;
      }
      axios
        .get(`${baseURL}/profile/`, {
          headers: {
            Authorization: `Bearer ${session?.accessToken}`,
          },
        })
        .then(response => {
          setUserData(response.data.data);
          setIsLoggedIn(true);
        })
        .catch(error => {
          console.log(error);
          setIsLoggedIn(false);
          signOut();
        });
    } else {
      setIsLoggedIn(false);
      setUserData(null);
    }
  }, [session, status]);

  useEffect(() => {
    if (userData?.organizations?.length > 0) {
      setProfileDropdownItems([
        { key: 'profilePage', label: 'Profile', href: '/profile' },
        {
          key: 'organization',
          label: 'Your Organization',
          href: `/organization/${userData.organizations[0]}`,
        },
        { key: 'logout', label: 'Logout', onClick: () => signOut() },
      ]);
    } else {
      setProfileDropdownItems([
        { key: 'profilePage', label: 'Profile', href: '/profile' },
        { key: 'logout', label: 'Logout', onClick: () => signOut() },
      ]);
    }
  }, [userData]);

  const openLoginModal = () => {
    const redirectPath = `${pathname}${utmSearchParams ? '?utm=' + utmSearchParams : ''}`;
    setRedirectPath(redirectPath);
    setLoginModalOpen(true);
    setSignupModalOpen(false);
  };

  const openSignUpModal = () => {
    const redirectPath = `${pathname}${utmSearchParams ? '?utm=' + utmSearchParams : ''}`;
    setRedirectPath(redirectPath);
    setSignupModalOpen(true);
    setLoginModalOpen(false);
  };

  const closeModal = () => {
    setLoginModalOpen(false);
    setSignupModalOpen(false);
  };

  const navbarItems = [
    { name: 'Events', href: '/event' },
    // { name: 'Community', href: '/community' },
    { name: 'Organize', href: '/organize-event' },
    // { name: 'Blog', href: '/Blog' },
    { name: 'Support', href: '/support' },
  ];

  return (
    <>
      {/* Navbar */}
      <Navbar isBordered isBlurred={false} className="md:py-1" maxWidth="full">
        <NavbarBrand>
          <Link href="/">
            <Image src={lightLogo} alt="" priority />
          </Link>
        </NavbarBrand>

        {/* Center Items */}
        <NavbarContent
          className="lg:block hidden border-1 rounded-full lg:px-4 md:px-2 h-14"
          justify="center"
        >
          <div className="flex flex-row items-center gap-2 px-5">
            {navbarItems.map((item, index) => (
              <Link
                key={index}
                className="text-black hover:font-medium py-3.5 px-3 min-w-[120px] hover:text-[#005D44] flex items-center justify-center relative group"
                href={item.href}
              >
                <span className="font-sans text-lg whitespace-nowrap">
                  {item.name}
                </span>
                <span className="absolute bottom-0 left-0 w-full h-[3px] bg-[#005D44] rounded-lg transform scale-x-0 transition-transform group-hover:scale-x-100"></span>
              </Link>
            ))}
          </div>
        </NavbarContent>

        {/* Login/Profile Section */}
        <NavbarContent className=" items-center text-center" justify="end">
          {isLoggedIn ? (
            <div className="flex items-center gap-2">
              {/* Desktop View */}
              <div className="hidden md:flex items-center gap-2">
                {userData?.profile_picture ? (
                  <Avatar profileImageUrl={userData?.profile_picture} />
                ) : (
                  <Avatar
                    userName={userData?.first_name + ' ' + userData?.last_name}
                  />
                )}
                <Dropdown>
                  <DropdownTrigger>
                    <div className="cursor-pointer">
                      {userData?.first_name + ' ' + userData?.last_name}
                    </div>
                  </DropdownTrigger>
                  <DropdownMenu aria-label="Profile Actions">
                    {profileDropdownItems.map(item => (
                      <DropdownItem
                        key={item.key}
                        color="primary"
                        onClick={
                          item.onClick
                            ? item.onClick
                            : () => router.push(item.href)
                        }
                      >
                        {item.label}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </Dropdown>
              </div>

              {/* Mobile View */}
              <div className="md:hidden">
                <Dropdown>
                  <DropdownTrigger>
                    <div className="cursor-pointer">
                      {userData?.profile_picture ? (
                        <Avatar profileImageUrl={userData?.profile_picture} />
                      ) : (
                        <Avatar
                          userName={
                            userData?.first_name + ' ' + userData?.last_name
                          }
                        />
                      )}
                    </div>
                  </DropdownTrigger>
                  <DropdownMenu aria-label="Profile and Navigation Actions">
                    <DropdownItem
                      key="user-name"
                      className="h-14 gap-2"
                      isDisabled
                    >
                      <p className="font-bold">
                        {userData?.first_name + ' ' + userData?.last_name}
                      </p>
                    </DropdownItem>
                    {/* Navigation Items */}
                    {navbarItems.map((item, index) => (
                      <DropdownItem
                        key={`nav-${index}`}
                        onClick={() => router.push(item.href)}
                      >
                        {item.name}
                      </DropdownItem>
                    ))}
                    {/* Profile Items */}
                    {profileDropdownItems.map(item => (
                      <DropdownItem
                        key={item.key}
                        color="primary"
                        onClick={
                          item.onClick
                            ? item.onClick
                            : () => router.push(item.href)
                        }
                      >
                        {item.label}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </Dropdown>
              </div>
            </div>
          ) : (
            <>
              <CTAButton
                onClick={openLoginModal}
                as={Link}
                size="lg"
                className="md:block hidden box-content  font-medium text-lg"
              >
                Login
              </CTAButton>
              <CTAButton
                onClick={openLoginModal}
                as={Link}
                size="sm"
                className="md:hidden box-content"
              >
                Login
              </CTAButton>
            </>
          )}
        </NavbarContent>
      </Navbar>

      {/* Login Modal */}
      {isLoginModalOpen && (
        <SignInForm
          isModalOpen={isLoginModalOpen}
          closeModal={closeModal}
          onSwitch={openSignUpModal}
          pathname={pathname}
          utmParams={utmSearchParams}
          registerRedirect={redirectPath}
        />
      )}

      {/* Register Modal */}
      {isSignupModalOpen && (
        <RegisterForm
          isModalOpen={isSignupModalOpen}
          closeModal={closeModal}
          onSwitch={openLoginModal}
          pathname={pathname}
          utmParams={utmSearchParams}
        />
      )}
    </>
  );
}
