import Link from 'next/link';
import Image from 'next/image';
import { socialLinks } from '@/config/footerConfig';

const SocialLinks = ({ className = '' }) => (
  <div className={`flex space-x-6 py-4 ${className}`}>
    {socialLinks.map(({ href, icon, alt }) => (
      <Link
        key={alt}
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        className="hover:opacity-80 transition-opacity"
      >
        <Image src={icon} alt={alt} width={20} height={20} />
      </Link>
    ))}
  </div>
);

export default SocialLinks;
