'use client'
import Footer from './Footer';
import { useSession } from 'next-auth/react';
import React, { Suspense } from 'react';
import { Toaster } from 'react-hot-toast';
import NewFooter from './NewFooter';
import PrimaryNavbar from './PrimaryNavbar';

const Layout = ({ children }) => {
  const { data: session, status } = useSession();

  return (
    <main>
      <div>
        <Toaster />
      </div>

      <Suspense fallback={<div>Loading...</div>}>
        <PrimaryNavbar />
      </Suspense>

      {children}
      <NewFooter/>
    </main>
  );
};

export { Layout };
