'use client';
import React from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { useSearchParams } from 'next/navigation';

// export const metadata = {
//   title: 'Authenticate',
//   description: 'Authentication',
// };

const page = () => {
  return (
    <>
      <Toaster />
      {/* <AuthCard /> */}
    </>
  );
};

export default page;



export function useSearchParamsWrapper() {
  const searchParams = useSearchParams();
  return {
    searchParams,
    getParam: (param) => searchParams.get(param),
    hasParam: (param) => searchParams.has(param),
  };
}
