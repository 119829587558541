'use client';
import React from 'react';
import Link from 'next/link';
import { Button, Input } from '@nextui-org/react';
import Image from 'next/image';
import lightLogo from '../../assets/images/BrandLogo.png';
import { navigationLinks } from '@/config/footerConfig';
import FooterNavSection from './FooterNavSection';
import CTAButton from '../commons/CTAButton';

import SocialLinks from './SocialLinks';

const NewFooter = () => {
  return (
    <footer className="bg-gray-100 md:pt-14 mt-4 border-1">
      <div className="layoutBox px-4 md:px-0">
        <div className="lg:flex flex-wrap justify-between mb-5 mt-14 md:mt-0 md:mb-8">
          {/* Left Section - Logo and Socials for Web */}
          <div className="md:block hidden mb-8">
            <Image
              className="mb-4"
              src={lightLogo}
              height={90}
              alt="logo"
              priority
            />
            <p className="font-medium text-lg font-sans">Follow us</p>
            <SocialLinks />
          </div>

          {/* Navigation Sections */}
          <div className="lg:block hidden lg:flex lg:space-x-16 mb-8">
            <FooterNavSection title="Company" links={navigationLinks.company} />
            <FooterNavSection
              title="Resources"
              links={navigationLinks.resources}
            />
            <FooterNavSection title="Legal" links={navigationLinks.legal} />
          </div>

          {/* Mobile Navigation */}
          <div className="lg:hidden block space-y-8">
            <FooterNavSection title="Company" links={navigationLinks.company} />
            <FooterNavSection
              title="Resources"
              links={navigationLinks.resources}
            />
            <FooterNavSection title="Legal" links={navigationLinks.legal} />
          </div>

          {/* Subscribe Section */}
          <NewsletterSection />

          {/* Mobile Logo and Social Links */}
          <div className="md:hidden flex justify-between pt-6">
            <Image
              className="mb-4"
              src={lightLogo}
              width={110}
              alt="logo"
              priority
            />
            <SocialLinks />
          </div>

          {/* Footer Bottom */}
          <FooterBottom />
        </div>
      </div>
    </footer>
  );
};

const NewsletterSection = () => (
  <div className="space-y-4 md:mb-8">
    <h3 className="font-medium font-sans text-lg md:text-xl">
      Stay up to date with Hackingly!
    </h3>
    <form className="flex items-center border-gray-300 bg-white rounded-2xl overflow-hidden">
      <input
        type="email"
        placeholder="Enter your email address"
        className=" py-2 px-4 text-sm md:text-md  font-2xl font-sans rounded-2xl focus:outline-none bg-[#ffffff] min-w-[200px]"
      />
      {/* <Button
        type="submit"
        className="md:py-7 md:px-14 text-sm md:text-lg shadow-inner-glow bg-[#097969] shadow-2xl text-white md:rounded-2xl hover:bg-green-700 whitespace-nowrap"
      > */}
      <CTAButton>Subscribe</CTAButton>
    </form>
  </div>
);

const FooterBottom = () => (
  <>
    <div className="flex w-full justify-between items-center text-xs font-sans hidden lg:flex">
      <div className="text-start relative">
        <p className="font-medium text-lg">Collaborate With Hackingly</p>
        <p className="text-base text-gray-600">
          Empower Talent To Transform Ideas Into Impactful Innovations
        </p>
      </div>
      <Copyright className="text-center mx-auto transform -translate-x-36" />
    </div>
    <div className="pb-8 text-center font-sans text-gray-600 text-sm sm:block lg:hidden">
      <Copyright />
    </div>
  </>
);

const Copyright = ({ className }) => (
  <div className={className}>
    <p className="text-sm font-normal">
      © {new Date().getFullYear()}{' '}
      <Link href="https://hackingly.in/">Hackingly™</Link>. All Rights
      Reserved.
    </p>
    <p className="text-sm font-normal">
      Crafted with <span className="text-red-500">❤</span> by Giby Technologies
      Private Limited
    </p>
  </div>
);

export default NewFooter;
