import { Button } from '@nextui-org/react';

const CTAButton = ({
  children,
  onClick,
  className = '',
  size = 'lg',
  variant = 'ghost',
  color = 'none',
}) => {
  return (
    <Button
      onClick={onClick}
      size={size}
      variant={variant}
      color={color}
      className={`bg-[#097969] text-white border-[#097969] border-1 shadow-inner-glow font-sans hover:bg-[#005D44] hover:shadow-inner-glow ${className}`}
    >
      {children}
    </Button>
  );
};

export default CTAButton;
