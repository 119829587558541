import Link from 'next/link';

const FooterNavSection = ({ title, links }) => (
  <div className="space-y-4">
    <h3 className="font-medium font-sans text-lg md:text-xl">{title}</h3>
    <ul className="leading-8 space-y-1 font-sans text-md text-[#4F4F4F]">
      {links.map(({ href, label, isNew }) => (
        <li key={label}>
          <Link href={href}>
            {label}
            {isNew && <span className="text-red-500 text-xs ml-2">New</span>}
          </Link>
        </li>
      ))}
    </ul>
  </div>
);

export default FooterNavSection;
